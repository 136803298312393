import React from "react"

import Layout from "../components/Layout"
import PageTitleSmall from "../components/PageTitleSmall"
import SEO from "../components/seo"
import DeliveryBlock from "../components/DeliveryBlock"
import MapBlock from "../components/MapBlock"
import ContactBlock from "../components/ContactBlock"
import FeedbackBlock from "../components/FeedbackBlock"
import OpeningHoursBlock from "../components/OpeningHoursBlock"
import img from "../images/img/bg-contact.jpg"

const Kontakt = () => (
  <Layout>
    <SEO title="Kontakt" />
    <PageTitleSmall title="Kontakt" img={img} />
    <ContactBlock />
    <DeliveryBlock />
    <MapBlock />
    <FeedbackBlock />
    <OpeningHoursBlock />
  </Layout>
)

export default Kontakt
