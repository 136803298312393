import React from "react"
import "./index.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhoneAlt, faEnvelope } from "@fortawesome/free-solid-svg-icons"
class ContactBlock extends React.Component {
  render() {
    return (
      <section className="contact">
        <h3>
          Sie haben Anregungen, Feedback oder möchten Vorschläge mit uns teilen?
        </h3>
        <div className="contact__options">
          <ul>
            <a href="mailto:service@bosphorus-restaurant.at">
              <li>
                <FontAwesomeIcon icon={faEnvelope} />
                <h4>Schriftlich kontaktieren.</h4>
              </li>
            </a>
            <a href="tel:+43528872299">
              <li>
                <FontAwesomeIcon icon={faPhoneAlt} />
                <h4>Jetzt anrufen.</h4>
              </li>
            </a>
          </ul>
        </div>
      </section>
    )
  }
}

export default ContactBlock
